import React from 'react'
import { graphql } from 'gatsby'
import { BlogPage } from '../components'
 
const BlogPostTemplate = ({ data, location }) => {
  return (
    <BlogPage 
      location={location} 
      data={data}
    />
  )
}

export const pageQuery = graphql`
  query BlogPosts($slug: String!) {
    post: markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      frontmatter {
        title
        description
        tags
        author {
          frontmatter {
            authorName
            email
            linkedin
            twitter
          }
        }
        date(formatString: "MMMM DD, YYYY")
        relativeDate: date(fromNow: true)
        featuredpost
        featuredimage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      headings(depth: h2) {
        value
      }
      html
      fields {
        slug
      }
    }
    tags: allMarkdownRemark(
      filter: {
        frontmatter: {templateKey: {eq: "blog"}}}, 
      ) {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }
  }
`

export default BlogPostTemplate
